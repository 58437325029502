<template>
  <b-card title="Re-Create Statements">
    <b-spinner
      v-if="isLoading"
      small
      label="Small Spinner"
    />

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-row v-if="!isLoading && !loadingError">
      <b-col cols="12">
        <b-form-group
          label="Party"
        >
          <custom-v-select
            v-model="selectedParty"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="partyOptions"
            placeholder="Select Party"
            :clearable="false"
            label="text"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-button
          variant="primary"
          :disabled="submiting || !selectedParty"
          @click="submit"
        >
          Re-Create
          <b-spinner
            v-if="submiting"
            small
            label="Small Spinner"
          />
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BButton, BSpinner, BCol, BFormGroup, BRow, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BCol,
    BFormGroup,
    BRow,
    BAlert,
    CustomVSelect,
  },
  data() {
    return {
      isLoading: true,
      loadingError: null,
      submiting: false,
      partyOptions: [],
      selectedParty: null,
    }
  },
  async created() {
    this.fetchOptions()
  },
  methods: {
    async fetchOptions() {
      this.isLoading = true
      let res
      try {
        res = await axios.get('/accounts/buyers_options')
        this.partyOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Error fetching party options'
      }
      this.isLoading = false
    },

    submit() {
      this.submiting = true

      axios.post('accounts/re_generate_statements', { party_id: this.selectedParty.id })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.submiting = false
        })
        .catch(error => {
          const message = error?.response?.data?.error || 'Somthing went wrong'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.submiting = false
        })
    },
  },
}
</script>
