<template>
  <div>
    <b-row>
      <b-col md="12">
        <filters
          @filtersUpdated="filtersChangeHandler"
        />
      </b-col>
    </b-row>

    <template v-if="filtersInitialized">
      <b-row>
        <b-col md="9">
          <payment-status
            :filters="filters"
          />
        </b-col>
        <b-col md="3">
          <latest-bill-details />
          <missing-statement-widget />
          <missing-area-widget />
          <re-create-statements />
          <missing-bill-details />
        </b-col>
      </b-row>
      <sales-statistics
        :filters="filters"
      />
      <month-wise-sales-statistics />
    </template>
  </div>
</template>

<script>
import Filters from '@/components/Dashboard/Filters/Filters.vue'
import PaymentStatus from '@/components/Dashboard/PaymentStatus.vue'
import SalesStatistics from '@/components/Dashboard/SalesStatistics/SalesStatistics.vue'
import MonthWiseSalesStatistics from '@/components/Dashboard/MonthWiseSalesStatistics.vue'

import MissingStatementWidget from '@/components/Dashboard/MissingStatementWidget.vue'
import ReCreateStatements from '@/components/Dashboard/ReCreateStatements.vue'
import LatestBillDetails from '@/components/Dashboard/LatestBillDetails.vue'
import MissingBillDetails from '@/components/Dashboard/MissingBillDetails.vue'

import { BRow, BCol } from 'bootstrap-vue'
import MissingAreaWidget from '@/components/Dashboard/MissingAreaWidget.vue'

export default {
  components: {
    BRow,
    BCol,
    PaymentStatus,
    SalesStatistics,
    MissingStatementWidget,
    ReCreateStatements,
    LatestBillDetails,
    Filters,
    MonthWiseSalesStatistics,
    MissingBillDetails,
    MissingAreaWidget,
  },
  data() {
    return {
      filtersInitialized: false,
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
      if (this.filtersInitialized === false) {
        this.filtersInitialized = true
      }
    },
  },
}
</script>
