<template>
  <b-card no-body>
    <b-card-header>
      <h4>Sales Statistics - {{ title }}</h4>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        type="line"
        height="350"
        :series="series"
        :options="chartOptions"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import formatMixin from '@/mixins/formatMixin'
import { BCard, BCardHeader, BCardBody } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
  },
  mixins: [formatMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    series() {
      const series = [
        {
          name: 'Sales',
          type: 'column',
          data: this.chartData.y,
        },
      ]

      if (this.chartData.y1 !== undefined) {
        series.push({
          name: 'Average Sale',
          type: 'line',
          data: this.chartData.y1,
        })
      }
      return series
    },
    yAxis() {
      const yAxis = [{
        title: {
          text: 'Sales',
          rotate: -90,
          style: {
            color: '#826af9',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            fontWeight: 400,
          },
        },
      }]
      if (this.chartData.y1 !== undefined) {
        yAxis.push({
          opposite: true,
          title: {
            text: 'Average Sale',
            rotate: 90,
            style: {
              color: '#d2b0ff',
              fontSize: '14px',
              fontFamily: 'Montserrat',
              fontWeight: 400,

            },
          },
        })
      }
      return yAxis
    },
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          y: {
            formatter: value => this.formateNumber(value),
          },
        },
        colors: ['#826af9', '#d2b0ff'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        stroke: {
          width: [0, 3],
        },
        labels: this.chartData.x,
        yaxis: this.yAxis,
      }
    },
  },
}
</script>
