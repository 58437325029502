<template>
  <b-card>
    <b-spinner
      v-if="isLoading"
      small
      label="Small Spinner"
    />

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>
    <span v-if="!isLoading && !loadingError">
      <a
        :href="actionUrl"
        target="_blank"
      >
        <b-badge
          pill
          variant="danger"
        >
          {{ areaCount }}
        </b-badge>
      </a> Buyer(s) missing Area.
    </span>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BAlert, BBadge,
} from 'bootstrap-vue'
import axios from 'axios'

import getEnv from '@/utils/env'

export default {
  components: {
    BCard,
    BSpinner,
    BAlert,
    BBadge,
  },
  data() {
    return {
      isLoading: true,
      loadingError: null,
      areaCount: null,
      actionUrl: `${getEnv('VUE_APP_ADMIN_URL')}/accounts/buyer/?area__isnull=True`,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get('accounts/count_buyers_without_area')
        .then(res => {
          this.areaCount = res.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.isLoading = false
        })
    },
  },
}
</script>
