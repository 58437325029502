<template>
  <div>
    <b-card no-body>
      <b-card-header class="align-items-start">
        <h4>Sales Statistics</h4>
        <div class="flex-grow-1 ml-1">
          <b-spinner
            v-if="loading"
            small
            label="Small Spinner"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="3">
            <b-form-group label="Date Range">
              <flat-picker
                v-model="dateRange"
                :config="{ mode: 'range'}"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-alert
          variant="danger"
          :show="loadingError ? true : false"
        >
          <div class="alert-body">
            <p>
              {{ loadingError }}
            </p>
          </div>
        </b-alert>
      </b-card-body>
    </b-card>

    <template v-if="!loadingError">
      <sales-statistics-chart
        title="Area Wise"
        :chart-data="chartData.areaWise"
      />
      <sales-statistics-chart
        title="Discount Wise"
        :chart-data="chartData.discountWise"
      />
      <sales-statistics-chart
        title="Month Wise"
        :chart-data="chartData.monthWise"
      />
      <sales-statistics-chart
        title="Week Wise"
        :chart-data="chartData.weekWise"
      />
      <sales-statistics-chart
        title="Salesman Wise"
        :chart-data="chartData.salesmanWise"
      />
    </template>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup, BAlert, BSpinner, BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import axios from 'axios'
import moment from 'moment-timezone'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import SalesStatisticsChart from './SalesStatisticsChart.vue'

export default {
  components: {
    FlatPicker,
    BCol,
    BRow,
    SalesStatisticsChart,
    BFormGroup,
    BAlert,
    BSpinner,
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateRange: null,
      loading: false,
      loadingError: null,
      chartData: {
        areaWise: { x: [], y: [], y1: [] },
        discountWise: { x: [], y: [], y1: [] },
        monthWise: { x: [], y: [] },
        weekWise: { x: [], y: [] },
        salesmanWise: { x: [], y: [], y1: [] },
      },
    }
  },
  watch: {
    dateRange() {
      if (this.initalized) {
        this.fetchData()
      }
    },
    filters() {
      this.fetchData()
    },
  },
  created() {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')
    this.dateRange = `${startOfMonth} to ${endOfMonth}`
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (!this.dateRange) {
        return
      }
      const [fromDate, toDate] = this.dateRange.split(' to ')
      if (!fromDate || !toDate) {
        return
      }
      this.loading = true
      axios.post('/accounts/sales_summary', { filters: { from_date: fromDate, to_date: toDate, ...this.filters } })
        .then(res => {
          this.chartData = {
            areaWise: res.data.area_wise,
            discountWise: res.data.discount_wise,
            monthWise: res.data.month_wise,
            weekWise: res.data.week_wise,
            salesmanWise: res.data.salesman_wise,
          }
          this.loadingError = null
          this.loading = false
          this.initalized = true
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Error fetching chart data'
          this.loading = false
          this.initalized = true
        })
    },
  },
}
</script>
