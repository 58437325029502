<template>
  <b-modal
    v-model="showModal"
    title="Delete Filter"
    centered
    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-card-text>
      Are you sure you want to delete filter {{ filter.name }}?
    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="danger"
        :disabled="deleting"
        @click="ok()"
      >
        Delete
        <b-spinner
          v-if="deleting"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      deleting: false,
    }
  },
  methods: {
    submitHandler(event) {
      event.preventDefault()
      this.deleting = true

      axios.delete(`/accounts/saved_filter/${this.filter.id}/`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Filter deleted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.$emit('filter-deleted')
          this.showModal = false
          this.deleting = false
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.error || 'Error deleting filter',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.deleting = false
        })
    },
  },
}
</script>
