<template>
  <b-card no-body>
    <b-card-header class="align-items-start">
      <h4>Filters</h4>
      <div class="flex-grow-1 ml-1">
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </div>
    </b-card-header>

    <b-card-body>
      <b-alert
        variant="danger"
        :show="loadingError ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ loadingError }}
          </p>
        </div>
      </b-alert>

      <template v-if="!loadingError">
        <validation-observer
          ref="filterForm"
          mode="eager"
        >
          <b-row
            v-if="filterMode === null"
            class="align-items-center"
          >
            <b-col md="3">
              <b-form-group
                label="Filter"
              >
                <custom-v-select
                  v-model="filter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="filters"
                  label="name"
                  @input="setFiltersData"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <feather-icon
                v-b-tooltip.hover="{boundary:'window'}"
                title="Add Filter"
                icon="PlusIcon"
                size="20"
                class="cursor-pointer mr-1"
                :class="{
                  'text-primary': filterMode === 'add'
                }"
                @click="addFilter"
              />

              <feather-icon
                v-if="filter"
                v-b-tooltip.hover="{boundary:'window'}"
                title="Edit Filter"
                icon="Edit2Icon"
                size="20"
                class="cursor-pointer mr-1"
                :class="{
                  'text-primary': filterMode === 'edit'
                }"
                @click="editFilter"
              />

              <feather-icon
                v-if="filter"
                v-b-tooltip.hover="{boundary:'window'}"
                title="Delete Filter"
                icon="TrashIcon"
                size="20"
                class="cursor-pointer mr-1"
                :class="{
                  'text-primary': filterMode === 'edit'
                }"
                @click="deleteFilter = filter"
              />
            </b-col>
          </b-row>

          <h5 v-if="filterMode !== null">
            {{ filterMode === 'add' ? 'Create Filter' : 'Edit Filter' }}
          </h5>

          <b-row>
            <b-col md="3">
              <b-form-group
                label="Area"
                label-for="area"
              >
                <custom-v-select
                  id="area"
                  v-model="filtersData.areas"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="areaOptions"
                  label="text"
                  placeholder="Select Area"
                  multiple
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  :reduce="option => option.id"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Salesman"
                label-for="salesman"
              >
                <custom-v-select
                  id="salesman"
                  v-model="filtersData.salesmans"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="salesmanOptions"
                  label="text"
                  placeholder="Select Salesman"
                  multiple
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  :reduce="option => option.id"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Discount"
                label-for="discount"
              >
                <custom-v-select
                  id="discount"
                  v-model="filtersData.discounts"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="discountOptions"
                  placeholder="Select Discount"
                  multiple
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Party"
                label-for="party"
              >
                <custom-v-select
                  id="party"
                  v-model="filtersData.parties"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="partyOptions"
                  label="text"
                  placeholder="Select Party"
                  multiple
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  :reduce="option => option.id"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Statement Template"
                label-for="statement-template"
              >
                <custom-v-select
                  id="statement-template"
                  v-model="filtersData.statement_templates"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statementTemplateOptions"
                  label="text"
                  placeholder="Select Statement Template"
                  multiple
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  :reduce="option => option.id"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <template v-if="filterMode != null">
            <b-row
              class="align-items-center"
            >
              <b-col md="3">
                <b-form-group
                  label="Filter name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Filter name"
                    vid="filterName"
                    rules="required"
                  >
                    <b-form-input
                      v-model="filterName"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-checkbox
                  v-model="defaultFilter"
                  :value="true"
                  :unchecked-value="false"
                >
                  Set as default filter
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-button
              variant="primary"
              :disabled="savingFilter"
              @click="submitFilter"
            >
              Submit
              <b-spinner
                v-if="savingFilter"
                small
                label="Small Spinner"
              />
            </b-button>
            <b-button
              class="ml-1"
              variant="secondary"
              @click="filterMode = null"
            >
              Cancel
            </b-button>
          </template>
        </validation-observer>
      </template>

      <delete-filter
        v-if="deleteFilter"
        :filter="deleteFilter"
        @modal-closed="deleteFilter = null"
        @filter-deleted="refreshFilters(false)"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BCard, BButton, BFormInput, BFormCheckbox, BSpinner, BCardHeader, BCardBody, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isEqual } from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import DeleteFilter from './DeleteFilter.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BCard,
    CustomVSelect,
    BButton,
    BFormInput,
    BFormCheckbox,
    DeleteFilter,
    BSpinner,
    BCardHeader,
    BCardBody,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      areaOptions: [],
      salesmanOptions: [],
      discountOptions: [
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      ],
      partyOptions: [],
      statementTemplateOptions: [],
      filters: [],
      filter: null,
      filtersData: {
        areas: [],
        salesmans: [],
        discounts: [],
        parties: [],
        statement_templates: [],
      },
      filterName: null,
      defaultFilter: false,
      filterMode: null,
      deleteFilter: null,
      savingFilter: false,
      loading: true,
      loadingError: null,
      oldOutputFiltersData: {},
      initialized: false,
    }
  },
  computed: {
    outputFiltersData() {
      const filtersData = {}
      if (this.filtersData.areas.length > 0) {
        filtersData.areas = this.filtersData.areas
      }
      if (this.filtersData.salesmans.length > 0) {
        filtersData.salesmans = this.filtersData.salesmans
      }
      if (this.filtersData.discounts.length > 0) {
        filtersData.discounts = this.filtersData.discounts
      }
      if (this.filtersData.parties.length > 0) {
        filtersData.parties = this.filtersData.parties
      }
      if (this.filtersData.statement_templates.length > 0) {
        filtersData.statement_templates = this.filtersData.statement_templates
      }
      return filtersData
    },
  },
  watch: {
    outputFiltersData: {
      deep: true,
      handler() {
        if (!this.initialized) {
          return
        }
        if (isEqual(this.oldOutputFiltersData, this.outputFiltersData)) {
          return
        }
        this.emitOutputFiltersData()
      },
    },
  },
  async created() {
    this.initializeFilters()
  },
  methods: {
    async initializeFilters() {
      await this.fetchOptions()
      if (this.loadingError) {
        return
      }

      await this.refreshFilters(true)
      if (this.loadingError) {
        return
      }

      this.emitOutputFiltersData()
      this.initialized = true
    },
    async fetchOptions() {
      this.loading = true
      let res
      try {
        res = await axios.get('/accounts/areas_options')
        this.areaOptions = res.data.data

        res = await axios.get('/accounts/salesmans_options')
        this.salesmanOptions = res.data.data

        res = await axios.get('/accounts/buyers_options')
        this.partyOptions = res.data.data

        res = await axios.get('/accounts/statement_template_options')
        this.statementTemplateOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Error fetching filter options'
      }
      this.loading = false
    },
    async refreshFilters(selectDefaultFilter) {
      this.loading = true
      const oldSelectedFilter = this.filter

      let res
      try {
        res = await axios.get('/accounts/saved_filter/')
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Error fetching filters'
        this.loading = false
        return
      }

      this.filters = res.data
      this.filter = null
      if (selectDefaultFilter) {
        const filter = this.filters.find(savedFilter => savedFilter.default === true)
        if (filter) {
          this.filter = filter
        }
      } else if (oldSelectedFilter) {
        const filter = this.filters.find(savedFilter => savedFilter.id === oldSelectedFilter.id)
        if (filter) {
          this.filter = filter
        }
      }
      this.setFiltersData()
      this.loading = false
    },
    editFilter() {
      this.filterName = this.filter.name
      this.defaultFilter = this.filter.default
      this.filterMode = 'edit'
    },
    addFilter() {
      this.filterName = null
      this.defaultFilter = false
      this.filterMode = 'add'
    },
    async submitFilter() {
      const isFormValid = await this.$refs.filterForm.validate()
      if (!isFormValid) {
        return
      }

      this.savingFilter = true
      let req
      let successMessage
      let errorMessage
      if (this.filterMode === 'add') {
        req = axios.post('/accounts/saved_filter/', {
          name: this.filterName,
          filters: this.filtersData,
          default: this.defaultFilter,
        })
        successMessage = 'Filter created successfully'
        errorMessage = 'Error creating filter'
      } else {
        req = axios.patch(`/accounts/saved_filter/${this.filter.id}/`, {
          name: this.filterName,
          filters: this.filtersData,
          default: this.defaultFilter,
        })
        successMessage = 'Filter updated successfully'
        errorMessage = 'Error updating filter'
      }

      req.then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: successMessage,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.filterMode = null
        this.refreshFilters(false)
        this.savingFilter = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.error || errorMessage,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.savingFilter = false
      })
    },
    setFiltersData() {
      if (!this.filter) {
        this.filtersData = {
          areas: [],
          salesmans: [],
          discounts: [],
          parties: [],
          statement_templates: [],
        }
      } else {
        this.filtersData = {
          areas: this.filter.filters.areas || [],
          salesmans: this.filter.filters.salesmans || [],
          discounts: this.filter.filters.discounts || [],
          parties: this.filter.filters.parties || [],
          statement_templates: this.filter.filters.statement_templates || [],
        }
      }
    },
    emitOutputFiltersData() {
      this.oldOutputFiltersData = this.outputFiltersData
      this.$emit('filtersUpdated', this.outputFiltersData)
    },
  },
}
</script>
