<template>
  <b-card no-body>
    <b-card-header class="align-items-start">
      <h4>Sales Statistics - Last 6 Months</h4>
      <div class="flex-grow-1 ml-1">
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-alert
        variant="danger"
        :show="loadingError ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ loadingError }}
          </p>
        </div>
      </b-alert>

      <vue-apex-charts
        v-if="!loadingError"
        type="line"
        height="350"
        :series="series"
        :options="chartOptions"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BSpinner, BAlert,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import formatMixin from '@/mixins/formatMixin'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BSpinner,
    BAlert,
  },
  mixins: [formatMixin],
  data() {
    return {
      chartData: {
        x: [],
        y: [],
      },
      loading: true,
      loadingError: null,
    }
  },
  computed: {
    series() {
      return [
        {
          name: 'Sales',
          type: 'column',
          data: this.chartData.y,
        },
      ]
    },
    chartOptions() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: ['#826af9', '#d2b0ff'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        stroke: {
          width: [0, 3],
        },
        labels: this.chartData.x,
        yaxis: [{
          title: {
            text: 'Sales',
            rotate: -90,
            style: {
              color: '#826af9',
              fontSize: '14px',
              fontFamily: 'Montserrat',
              fontWeight: 400,
            },
          },
        }],
        tooltip: {
          y: {
            formatter: val => `${this.formateNumber(val)}`,
          },
        },
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      axios.get('/accounts/monthly_sales_summary')
        .then(res => {
          this.chartData = res.data.month_wise
          this.loadingError = null
          this.loading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Error fetching chart data'
          this.loading = false
        })
    },
  },
}
</script>
