<template>
  <b-card no-body>
    <b-card-header class="align-items-start">
      <h4>Payment Status</h4>
      <div class="flex-grow-1 ml-1">
        <b-spinner
          v-if="loading"
          small
          label="Small Spinner"
        />
      </div>
    </b-card-header>

    <b-card-body>

      <b-alert
        variant="danger"
        :show="loadingError ? true : false"
      >
        <div class="alert-body">
          <p>
            {{ loadingError }}
          </p>
        </div>
      </b-alert>

      <b-row>
        <b-col md="5">
          <b-form-group label="Date Range">
            <flat-picker
              v-model="rangeDate"
              :config="{ mode: 'range'}"
            />
          </b-form-group>
          <vue-apex-charts
            v-if="!loadingError"
            type="donut"
            height="350"
            :series="chartSeries"
            :options="chartOptions"
          />
        </b-col>
        <b-col
          md="7"
        >
          <b-table
            v-if="!loadingError && pendingItemDetails.length > 0"
            :items="pendingItemDetails"
            :fields="tableFields"
            caption-top
            show-empty
            class="compact-table"
            sticky-header="calc(40vh)"
          >
            <template #table-caption>
              Pending Payment Details
            </template>
            <template #cell(pending_amount)="{item}">
              {{ formateNumber(item.pending_amount) }}
            </template>
          </b-table>
        </b-col>
      </b-row>

    </b-card-body>
  </b-card>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BAlert, BCard, BCardHeader, BCardBody, BSpinner, BTable,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import moment from 'moment-timezone'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import formatMixin from '@/mixins/formatMixin'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    VueApexCharts,
    BAlert,
    BCard,
    BCardHeader,
    BCardBody,
    BSpinner,
    BTable,
    FlatPicker,
  },
  mixins: [formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingError: null,
      rangeDate: null,
      initalized: false,
      pendingItemDetails: [],
      tableFields: [
        { key: 'party__identifier', label: 'Party', sortable: true },
        {
          key: 'pending_amount', label: 'Pending Amount', sortable: true, class: 'text-right',
        },
      ],
      chartData: {
        receivedAmount: 0,
        pendingAmount: 0,
      },
      chartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          '#826bf8',
          '#FFA1A1',
        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
          // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter: val => `${this.formateNumber(val)}`,
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Total',
                  formatter: () => `${this.formateNumber(this.chartData.receivedAmount + this.chartData.pendingAmount)}`,
                },
              },
            },
          },
        },
        labels: ['Received', 'Pending'],
        tooltip: {
          y: {
            formatter: val => `${this.formateNumber(val)}`,
          },
        },
      },
    }
  },
  computed: {
    chartSeries() {
      return [this.chartData.receivedAmount, this.chartData.pendingAmount]
    },
  },
  watch: {
    rangeDate() {
      if (this.initalized) {
        this.fetchData()
      }
    },
    filters() {
      this.fetchData()
    },
  },
  created() {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')
    this.rangeDate = `${startOfMonth} to ${endOfMonth}`
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (!this.rangeDate) {
        return
      }
      const [fromDate, toDate] = this.rangeDate.split(' to ')
      if (!fromDate || !toDate) {
        return
      }
      this.loading = true
      axios.post('/accounts/sale_vs_collected',
        { filters: { from_date: fromDate, to_date: toDate, ...this.filters } })
        .then(res => {
          const resData = res.data
          this.chartData = {
            receivedAmount: resData.received_amount,
            pendingAmount: resData.pending_amount,
          }
          this.pendingItemDetails = resData.pending_details
          this.loading = false
          this.initalized = true
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Error fetching chart data'
          this.loading = false
          this.initalized = true
        })
    },
  },
}
</script>
