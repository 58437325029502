<template>
  <b-card title="Missing Bills">
    <b-row>
      <b-col lg="6">
        <b-form-group
          label="Year"
        >
          <custom-v-select
            v-model="financialYear"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="financialYearOptions"
            placeholder="Select Year"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-button
          variant="primary"
          :disabled="isLoading"
          style="margin-top:25px;"
          @click="getData"
        >
          Fetch
          <b-spinner
            v-if="isLoading"
            small
            label="Small Spinner"
          />
        </b-button>
      </b-col>
    </b-row>

    <b-alert
      variant="danger"
      :show="!isLoading && loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!isLoading && !loadingError && loaded">
      <div>
        <b-badge
          pill
          :variant="missingBills.length > 0 ? 'danger' : 'primary'"
        >{{ missingBills.length }}</b-badge>

        Missing Bills
      </div>
      <div>{{ missingBills.join(', ') }}</div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BAlert, BCol, BFormGroup, BRow, BBadge, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import moment from 'moment-timezone'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BCard,
    BSpinner,
    BAlert,
    BCol,
    BFormGroup,
    BRow,
    CustomVSelect,
    BBadge,
    BButton,
  },
  data() {
    return {
      isLoading: false,
      loadingError: null,
      financialYear: null,
      loaded: false,
      missingBills: [],
    }
  },
  computed: {
    financialYearOptions() {
      const options = []
      const startYear = this.financialStart()
      options.push(this.financialYearString(startYear - 1))
      options.push(this.financialYearString(startYear))
      return options
    },
  },
  created() {
    this.financialYear = this.financialYearString(this.financialStart())
  },
  methods: {
    financialStart() {
      const now = moment.utc().tz('Asia/Kolkata')
      const currentYear = now.year()
      const currentMonth = now.month()

      let startYear
      if (currentMonth > 2) { // 0 is jan, 2 is march
        startYear = currentYear
      } else {
        startYear = currentYear - 1
      }
      return startYear
    },
    financialYearString(startYear) {
      return `${startYear}-${moment.utc().tz('Asia/Kolkata').set({ year: startYear + 1 }).format('YY')}`
    },
    getData() {
      this.isLoading = true
      axios.get('accounts/missing_bills', {
        params: {
          financial_year: this.financialYear,
        },
      })
        .then(res => {
          this.missingBills = res.data.data
          this.loadingError = null
          this.isLoading = false
          this.loaded = true
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Error fetching missing bills'
          this.isLoading = false
        })
    },
  },
}
</script>
