<template>
  <b-card>
    <b-spinner
      v-if="isLoading"
      small
      label="Small Spinner"
    />

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>
    <span v-if="!isLoading && !loadingError">
      Latest Bill Details: <br>
      Bill No.: {{ bill_no }} <br>
      Date: {{ formatedDate(date) }}
    </span>
  </b-card>
</template>

<script>
import {
  BCard, BSpinner, BAlert,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'

export default {
  components: {
    BCard,
    BSpinner,
    BAlert,
  },
  mixins: [dateMixin],
  data() {
    return {
      isLoading: true,
      loadingError: null,
      bill_no: null,
      date: null,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get('accounts/latest_bill_details')
        .then(res => {
          this.bill_no = res.data.data.bill_no
          this.date = res.data.data.date
          this.isLoading = false
        })
        .catch(error => {
          this.loadingError = error?.response?.data?.error || 'Somthing went wrong'
          this.isLoading = false
        })
    },
  },
}
</script>
